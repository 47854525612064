<template lang="pug">
div
</template>

<script>

export default {
  name: 'order-detail',
  props: ['order_id'],

  async mounted() {
    console.log('order detail emit', this.order_id)
    this.$emit('open-modal', this.order_id)
  },

  beforeDestroy() {
    this.$emit('close-modal')
  },

  watch: {
    'order_id'(to) {
      console.log({to})
      this.$emit('open-modal', this.order_id)
    }
  }
}
</script>
